import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "ActivityIcon",
      to: "dashboard",
      title: "當日資料查詢"
    },
    // {
    //   icon: "fa-columns",
    //   title: "選單安排",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "/dashboard",
    //       title: "側邊選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/simple-menu/dashboard",
    //       title: "簡易選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/top-menu/dashboard",
    //       title: "上方選單",
    //       ignore: true
    //     }
    //   ]
    // },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置"
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "系統管理",
      subNodes: [
        {
          icon: "fa-magic",
          to: "user-wizard",
          title: "用戶註冊精靈"
        },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理"
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理"
        }
      ]
    },
    // {
    //   icon: "ActivityIcon",
    //   to: "action-log",
    //   title: "操作紀錄"
    // },
    // {
    //   icon: "ActivityIcon",
    //   to: "api-log",
    //   title: "API請求紀錄"
    // },
    {
      icon: "fa-map-marker-alt",
      to: "hotspot",
      title: "地點管理"
    },
    {
      icon: "fa-fire",
      to: "scenery",
      title: "熱門景點管理"
    },
    {
      icon: "fa-server",
      to: "ivar",
      title: "人流計數主機管理"
    },
    {
      icon: "fa-camera",
      to: "channel",
      title: "攝影機管理",
      // subNodes: [
      //   {
      //     icon: "fa-chart-bar",
      //     to: "channel",
      //     title: "攝影機資料"
      //   },
        // {
        //   icon: "fa-map",
        //   to: "channel/map",
        //   title: "攝影機位置地圖"
        // }
      // ]
    },
    {
      icon: "fa-users",
      to: "people-flow",
      title: "人流歷史紀錄查詢",
    },
    {
      icon: "fa-map",
      to: "all-map",
      title: "地圖全覽",
    }
  ]
};

export default sitemap;
