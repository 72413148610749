import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ErrorPage from './views/error-page/Main.vue'

const viewRotes: RouteRecordRaw[] = [
  // project views
  // { path: 'dashboard', component: () => import('./views/dashboard/Main.vue') },
  { path: 'configuration', component: () => import('./views/configuration/Main.vue'), meta: { rule: 'Administrator' } },
  { path: 'action-log', component: () => import('./views/action-log/Main.vue') },
  { path: 'role', component: () => import('./views/role/Main.vue') },
  { path: 'user', component: () => import('./views/user/Main.vue') },
  { path: 'user-wizard', component: () => import('./views/user-wizard/Main.vue') },
  { path: 'api-log', component: () => import('./views/api-log/Main.vue') },
  { path: 'ivar', component: () => import('./views/ivar/Main.vue') },
  { path: 'channel', component: () => import('./views/channel/Main.vue') },
  // { path: 'channel/map', component: () => import('./views/channel/Map.vue') },
  { path: 'hotspot', component: () => import('./views/hotspot/Main.vue') },
  { path: 'scenery', component: () => import('./views/scenery/Main.vue') },
  { path: 'people-flow', component: () => import('./views/people-flow/Main.vue') },
  { path: 'dashboard', component: () => import('./views/people-flow/Daily.vue') },
  { path: 'all-map', component: () => import('./views/all-map/Main.vue') },
]

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: 'dashboard', children: viewRotes, component: () => import('./layouts/side-menu/Main.vue') },
  { path: '/simple-menu', redirect: 'dashboard', children: viewRotes, component: () => import('./layouts/simple-menu/Main.vue') },
  { path: '/top-menu', redirect: 'dashboard', children: viewRotes, component: () => import('./layouts/top-menu/Main.vue') },
  { path: '/login', name: 'login', component: () => import('./views/login/Main.vue') },
  { path: '/register', name: 'register', component: () => import('./views/register/Main.vue') },
  { path: '/error-page', name: 'error-page', component: ErrorPage },
  { path: '/:pathMatch(.*)*', component: ErrorPage }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) { return savedPosition || { left: 0, top: 0 } }
})
