import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import actionLog from "./action-log";
import apiLog from "./api-log";
import channel from "./channel";
import hotspot from "./hotspot";
import ivar from "./ivar";
import peopleFlow from "./people-flow";
import scenery from "./scenery";

import pictureAlbum from "./picture-album";
import picture from "./picture";
import template from "./template";
import files from "./files";

const model = new Model("Qgis", {
  main,
  configuration,
  role,
  user,
  actionLog,
  apiLog,
  channel,
  hotspot,
  ivar,
  peopleFlow,
  scenery,

  pictureAlbum,
  picture,
  template,
  files
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data =>
  model.clients.unauthorized.post("System/Login", {
    account: data.account,
    password: data.password,
    token: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha
  });
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = message => model.clients.unauthorized.post("System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
